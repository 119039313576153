<template>
  <div
    v-if="productHydrated"
    class="ProductCard relative h-full"
    :class="{
      'px-12 desk:px-0': design === 'list',
    }"
  >
    <!-- * * * * * * * List view -->
    <div
      v-if="design === 'list'"
      class="
      flex flex-col desk:flex-row justify-between items-center w-full
      border-b border-light py-12 gap-12"
    >
      <div class="flex flex-row items-center justify-between w-full gap-32">
        <div class="shrink grow flex items-center gap-12 desk:gap-16">
          <div class="relative shrink-0 w-56 h-[72px] overflow-hidden group">
            <nuxt-link :to="link">
              <img
                v-if="!quickBuyActiveVariant.image"
                src="/icons/no-image.svg"
                class="object-cover w-full"
                alt="no image"
              >
              <nuxt-img
                v-else
                :src="quickBuyActiveVariant.image"
                provider="norce"
                preset="plpimage"
                :sizes="sizes"
                class="object-contain w-full h-full group-hover:scale-110 transition ease-out duration-300"
                :class="{
                  'object-cover': productHydrated.extendedLayout,
                }"
                :alt="productHydrated.name"
                :loading="placement > 1 ? 'lazy':'eager'"
              />
            </nuxt-link>
            <div class="imageOverlay absolute top-0 left-0 w-full h-full pointer-events-none" />
          </div>
          <div class="shrink">
            <nuxt-link :to="link">
              <div class="empty:hidden flex flex-col gap-6">
                <!-- campaign flags -->
                <div v-if="productHydrated.hasBulkDiscount" class="inline pr-6 type-xs text-dark whitespace-nowrap">
                  {{ productHydrated.isGiftCampaign ? $t('buyMore.plp.gift-flag') : $t('buyMore.plp.flag') }}
                </div>
                <div
                  v-for="flag in productHydrated.productCardDisplayFlags"
                  :key="flag.code"
                  class="inline pr-6"
                >
                  <span
                    v-if="shouldShowFlag(flag.code)"
                    class="whitespace-nowrap type-xs text-dark"
                    :class="{
                      'text-purplePrimary': flag.code === 'nyhet',
                    }"
                  >
                    {{ flag.text }}
                  </span>
                </div>
                <div
                  v-if="quickBuyActiveVariant.productHasExpired && productHydrated.productCardDisplayFlags.filter(f => f.code === NorceFlagCodes.DiscontinuedAndVisible).length === 0"
                  class="inline pr-6"
                >
                  <span class="whitespace-nowrap type-xs text-dark">
                    {{ $t('mypage.orderProposal.expired') }}
                  </span>
                </div>
                <div v-if="productHydrated.isHiddenProduct">
                  <img
                    alt=""
                    src="/icons/eye-closed.svg"
                    class="w-[16px] h-[16px]"
                  >
                </div>
              </div>
              <div class="type-xs desk:type-sm">
                <div class="type-tagline-xxs desk:type-tagline my-4 block">{{ productHydrated.brand.name }}</div>
                {{ quickBuyActiveVariant.name }}
                <span class="hidden">{{ productHydrated.priceFetchPartNo }}</span>
              </div>
            </nuxt-link>
          </div>
        </div>
        <div
          v-if="userStore.isLoggedIn && !product.isStructureArticle && !product.extendedLayout"
          class="block desk:hidden shrink-0"
        >
          <ProductPrice
            :crossell="false"
            :product-item="productHydrated"
            :active-variant="quickBuyActiveVariant"
            design="list"
            class="text-right"
          />
        </div>
      </div>
      <div class="flex flex-row justify-between gap-12 shrink-0 max-w-full mobOnly:w-full desk:min-w-[584px]">
        <SideScroll class="grow shrink">
          <VariantSelector
            v-if="userStore.isLoggedIn && !product.isStructureArticle && !product.extendedLayout"
            class="relative"
            :product-item="productHydrated"
            :active-variant="quickBuyActiveVariant"
            design="list"
            @update-active-variant="(variant) => quickBuyActiveVariant = variant"
          />
        </SideScroll>
        <div class="flex flex-row items-center justify-end gap-24">
          <div
            v-if="userStore.isLoggedIn && !product.extendedLayout"
            class="mobOnly:hidden shrink-0"
          >
            <ProductPrice
              :crossell="false"
              :product-item="productHydrated"
              :active-variant="quickBuyActiveVariant"
              design="list"
              class="text-right"
            />
          </div>
          <div
            v-if="userStore.isLoggedIn && !product.extendedLayout"
            class="relative"
          >
            <InstantBuyBox
              :product-item="productHydrated"
              :active-variant="quickBuyActiveVariant"
            />
          </div>
          <button
            v-if="!userStore.isLoggedIn && !product.extendedLayout"
            class="text-right btn--text"
            @click.prevent="showLogin"
          >
            {{ $t('price.notLoggedIn') }}
          </button>
          <div v-if="product.extendedLayout">
            <nuxt-link :to="link" class="btn btn--secondary btn--md whitespace-nowrap">
              {{ $t('productPage.info.about') }}
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
    <!-- * * * * /list view -->
    <!-- * * * * * * * Grid view -->
    <div v-else>
      <div class="relative aspect-product group overflow-hidden cursor-pointer">
        <div
          v-if="!image"
        >
          <img
            src="/icons/no-image.svg"
            class="object-cover"
            alt="no image"
          >
        </div>
        <div
          v-else
          class="w-full h-full flex items-center justify-center"
        >
          <nuxt-img
            :src="image"
            provider="norce"
            preset="plpimage"
            :sizes="sizes"
            class="max-w-[75%] max-h-[75%] h-auto w-auto group-hover:scale-105 transition ease-out duration-300"
            :class="{
              'max-w-full max-h-full object-cover': productHydrated.extendedLayout,
            }"
            :alt="productHydrated?.name || ''"
            :loading="placement > 1 ? 'lazy':'eager'"
          />
        </div>

        <nuxt-link
          :to="link"
          :class="{
            'pointer-events-none': userStore.isLoggedIn && pointShop
          }"
          @click="track"
        >
          <div class="imageOverlay absolute top-0 left-0 w-full h-full" />
        </nuxt-link>

        <div v-if="userStore.canFavorizeProducts && !productHydrated.extendedLayout" class="absolute top-8 right-8 desk:top-12 desk:right-12">
          <FavoriteButton
            :product="productHydrated"
            :design="design"
          />
        </div>

        <div
          v-if="showQuickbuy"
          class="absolute desk:bottom-12 desk:right-12 bottom-8 right-8"
        >
          <button
            v-if="quickBuyActiveVariant.canAddToCart"
            class="bg-darkest h-32 w-32 text-center flex items-center justify-center hover:bg-darker transition-all"
            :title="$t('quickbuy.button')"
            @click="openQuickbuy"
          >
            <img
              src="/icons/plus-inv.svg"
              class="w-20 h-20"
              alt=""
            >
          </button>
          <InstantBuyBox
            v-else
            :product-item="productHydrated"
            :active-variant="quickBuyActiveVariant"
          />
        </div>
        <div
          v-else-if="userStore.isLoggedIn && pointShop"
          class="absolute desk:bottom-12 bottom-8 left-0 w-full text-center"
        >
          <PointShopAmount :user-price="userPrice" :product="productHydrated" />
        </div>

        <!-- flags, top left -->
        <div class="type-xs leading-single absolute top-6 left-6">
          <div
            v-if="quickBuyActiveVariant && !quickBuyActiveVariant.canAddToCart && !quickBuyActiveVariant.productHasExpired"
            class="flex items-center px-4 py-2"
          >
            <StockStatus
              :variant="quickBuyActiveVariant"
              :product-item="productHydrated"
              :use-short-labels="true"
              :is-product-card="true"
            />
          </div>

          <div
            v-if="productHydrated.hasBulkDiscount"
            class="inline-block text-center leading-single px-4 py-4 text-dark"
          >
            {{ productHydrated.isGiftCampaign ? $t('buyMore.plp.gift-flag') : $t('buyMore.plp.flag') }}
          </div>
          <div
            v-for="flag in productHydrated.productCardDisplayFlags"
            :key="flag.code"
          >
            <div
              v-if="shouldShowFlag(flag.code)"
              :style="`background-color: ${flag.background}`"
              class="inline-block text-center leading-single px-4 py-4 text-dark"
              :class="{
                'type-tagline-xxs': flag.background,
                'text-lightest h-24 px-8 py-[7px] relative desk:top-4 desk:left-4': isDarkFlag(flag.background),
                'type-tiny': flag.code === 'colorMap',
                'text-purplePrimary': flag.code === 'nyhet',
                'text-dark': flag.groupCode === 'displayFlags',
              }"
            >
              <div
                v-if="flag.code === 'discontinuedAndVisible' || flag.code === 'preOrder'"
                class="inline-block w-6 h-6 mr-4 relative -top-1 rounded-full"
                :class="{
                  'bg-criticalDark': flag.code === 'discontinuedAndVisible',
                  'bg-warningPrimary': flag.code === 'preOrder',
                }"
              />
              {{ flag.text }}
            </div>
          </div>
          <div v-if="productHydrated.isHiddenProduct" class="leading-single px-4 py-4 text-dark">
            <img
              alt=""
              src="/icons/eye-closed.svg"
              class="w-[16px] h-[16px]"
            >
          </div>
        </div>
        <!-- more variants - bottom left -->
        <div
          v-if="productHydrated.hasVariants && quickBuyActiveVariant.canAddToCart && !pointShop"
          class="type-xs leading-single text-dark absolute bottom-8 left-8 p-8 pb-4"
          :class="{
            nextToQuickbuy: showQuickbuy
          }"
        >
          {{ $t('productList.product.hasVariants') }}
        </div>
      </div>
      <div
        class="px-12 pt-4 desk:px-8 desk:pt-12 pb-32"
        :class="{
          'w-full': !featured,
          'basis-1/2 shrink-0 bg-[#f6f6f6] desk:flex items-center': featured,
        }"
      >
        <nuxt-link
          :to="link"
          :class="{
            'pointer-events-none': userStore.isLoggedIn && pointShop
          }"
          @click="track"
        >
          <div class="type-tagline-xxs desk:type-tagline mb-4 inline-block">
            {{ productHydrated.brand.name }}
            <span class="hidden">{{ productHydrated.priceFetchPartNo }}</span>
          </div>

          <div class="type-xs desk:type-sm mb-8">{{ productHydrated.name }}</div>
          <div
            v-if="featured"
            class="type-sm mb-24"
            v-html="productHydrated.shortDescription"
          />

          <div v-if="productHydrated.extendedLayout" class="leading-single type-xs-medium desk:type-sm-medium">
            <div v-if="productHydrated.hasVariants">
              {{ $t('productPage.extended.shades', { num: productHydrated.partNumbers.length }) }}
            </div>
          </div>
          <div v-else>
            <div class="type-xs-medium desk:type-sm-medium mt-12">
              <div
                v-if="userPrice.status === 'logginIn' || userPrice.status === 'pending'"
                class="priceLoadBox"
              >
                {{ productHydrated.priceInfo.priceBeforeVatDisplay.replace(/\d/g, '&nbsp;') }}
              </div>
              <div
                v-if="userPrice.status === 'notLoggedIn'"
                class="btn--text btn--mob absolute bottom-20"
                @click.prevent="showLogin"
              >
                {{ $t('price.notLoggedIn') }}
              </div>

              <div
                v-if="userPrice.status === 'error'"
                class="text-criticalDark"
              >
                ---
              </div>

              <div
                v-if="userPrice.status === 'ok'"
              >
                <div
                  class="leading-single text-darkest"
                  :class="{
                    'text-criticalDark': isUserDiscounted
                  }"
                >
                  <span v-if="pointShop" class="text-criticalDark">
                    {{ userPrice.price?.pointsDisplay }}
                  </span>
                  <span v-else>
                    {{ userPrice.price?.priceBeforeVatDisplay }}
                  </span>

                  <div v-if="!pointShop && userPrice.price?.recommendedPriceDisplay" class="text-dark font-normal pt-6">
                    {{ $t('product.recommendedPrice.short') }} {{ userPrice.price?.recommendedPriceDisplay }}
                  </div>
                </div>
                <p
                  v-if="!pointShop && ((userPrice.price?.isCustomerPrice && userPrice.price?.discountPercentage > 0) || userPrice.price?.isCampaignPrice || userPrice.price?.isOnSale)"
                  class="mt-6 type-sm leading-single text-dark font-normal"
                  :class="{ 'hidden': pointShop }"
                >
                  {{ $t('productPage.price.original') }} {{ userPrice.price?.priceBeforeDiscountBeforeVatDisplay }}
                  <span v-if="userPrice.price?.isCustomerPrice">
                    ({{ $t('productPage.price.yourDiscount') }} <span
                      class="text-criticalDark"
                    >{{ userPrice.price?.discountPercentage }}%</span>)
                  </span>
                </p>
              </div>
            </div>
          </div>
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import FavoriteButton from '~/components/product/FavoriteButton.vue';
import { useUiStore } from '~/store/ui';
import { storeToRefs } from 'pinia';
import { ProductRaw } from '~/constants/types/algolia';
import Product, { ProductModel } from '~/models/product';
import { useCartStore } from '~/store/cart';
import { useGlobalContentStore } from '~/store/globalContent';
import { useUserStore } from '~/store/user';
import { isLight } from '~/util/colorUtil';
import PointShopAmount from '~/components/product/PointShopAmount.vue';
import { createDataLayerItem, sendDataLayer } from '~/composeables/useTracking';
import useLinkReplacer from '~/composeables/useLinkReplacer';
import { ref } from 'vue';
import { ProductVariantModel } from '~/models/productVariant';
import VariantSelector from '~/components/product-page/VariantSelector.vue';
import InstantBuyBox from '~/components/product/InstantBuyBox.vue';
import ProductPrice from '~/components/product-page/ProductPrice.vue';
import StockStatus from '~/components/product/StockStatus.vue';
import { NorceFlagCodes } from '~/constants/norceCodes';

const cartStore = useCartStore();
const userStore = useUserStore();
const globalContent = useGlobalContentStore();
const uiStore = useUiStore();
const { listImageSize } = storeToRefs(uiStore);

const { productUrl } = useLinkReplacer();
const props = defineProps<{
  product: ProductRaw | ProductModel,
  enableQuickBuy: boolean,
  placement: number, // the placement of list component
  index?: number, // the placement inside the list
  design: 'standard' | 'featured' | 'small' | 'list', // small looks like standard but more columns
  pointShop?: boolean,
  isUpsell?: boolean,
  useVariantImage?: boolean, // if the list from algolia is distinct = true, we show the variant image
  listIndex?: string,
}>();

defineEmits(['quickbuy']);

const productHydrated = props.product instanceof Product ? props.product : Product.create(props.product);
if (!(props.product instanceof Product)) {
  console.log('Using ProductCard from ProductRaw is not recommended');
}

const setPrimaryToActive = () => {
  if (productHydrated) {
    let primaryVariant = props.listIndex?.includes('price')
      ? productHydrated.variants.findIndex((f) => f.partNo === props.product.partNo)
      : productHydrated.variants.findIndex((f) => f.isPrimary);
    if (primaryVariant === -1) {
      primaryVariant = 0;
    }
    quickBuyActiveVariant.value = productHydrated.variants[primaryVariant];
  }
};
const quickBuyActiveVariant = ref<ProductVariantModel>(productHydrated.variants[0]);
setPrimaryToActive();

const image = computed(() => {
  if (props.useVariantImage && productHydrated.variantImage) {
    return productHydrated.variantImage;
  }
  return productHydrated.listImage;
});

const featured = computed(()=> {
  return props.design === 'featured';
});

const link = computed(()=> {
  return productUrl(productHydrated.url);
});

const userPrice = computed(()=> {
  return props.pointShop || props.listIndex?.includes('price') ?
    userStore.getPrice(productHydrated.partNo, props.isUpsell) :
    userStore.getPrice(productHydrated.priceFetchPartNo, props.isUpsell);
});

const openQuickbuy = async() => {
  cartStore.setCurrentlyBuying(productHydrated, props.isUpsell, !props.listIndex?.includes('price'));
  uiStore.setShowQuickBuy(true);
};

const track = () => {
  sendDataLayer(
    'select_item',
    [ createDataLayerItem(props.product) ],
    {
      'item_list_name': globalContent.currentStory?.name,
      'item_list_id': globalContent.currentStory?.id,
    }
  );
};

const sizes = computed(()=> {
  if (props.design === 'list') {
    return 'mob375:56px';
  }
  switch (listImageSize.value) {
    case 'lg':
      return 'mob375:100vw mob390:100vw mob414:100vw desk:250px';
    case 'sm':
      return 'mob375:184px mob390:191px mob414:203px desk:190px';
  }
  return 'mob375:184px mob390:191px mob414:203px desk:250px';
});

const showLogin = () => {
  uiStore.setShowSigninModal(true, true);
};

const isDarkFlag = (input: string | null | undefined) => {
  if (!input) {
    return false;
  }
  return !isLight(input);
};

const isUserDiscounted = computed(()=> {
  return userStore.isSalePrice(productHydrated.priceFetchPartNo);
});

const showQuickbuy = computed(()=> {
  return props.enableQuickBuy && userStore.isLoggedIn && !productHydrated.extendedLayout && !props.pointShop;
});

const shouldShowFlag = (code: string) => {
  if (code === 'onDemand') {
    const anyInStock = props.product.variantStockStatuses.some((s) => s.inStock > 0);
    return !anyInStock;
  }
  return true;
};

</script>

<style scoped lang="postcss">

.product-description {
  margin-top: 8px;
}

.priceLoadBox {
  @apply inline-block;
  color: rgba(0,0,0,0);
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate;
}

@keyframes spin {
  from {
    background-color: #DEDDD9;
  }
  to {
    background-color: #F5F4F0;
  }
}

.nextToQuickbuy {
  /* 100% - padding - button size - margin to button */
  max-width: calc(100% - 16px - 32px - 4px);
}

</style>
